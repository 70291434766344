<template>
  <div>
    <v-card class="mt-5" v-for="offer in this.offers" :key="offer.dismaster_id">
      <v-card-text>
        <v-row>
          <v-col cols="3"
            >PO
            <h2>{{ offer.po_number }}</h2>
          </v-col>
          <v-col cols="3"
            >Season
            <h2>{{ offer.season_name }}</h2></v-col
          >
          <v-col cols="3"
            >Offer
            <h2>{{ offer.offer_no }}</h2></v-col
          >
          <v-col cols="3">
            <v-btn
              elevation="0"
              :ripple="false"
              dark
              class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
              @click="confirm(offer)"
              >confirm</v-btn
            ></v-col
          >
        </v-row>

        <v-data-table
          :headers="OfferHeader"
          :items="offer.child"
          class="table"
          mobile-breakpoint="0"
          hide-default-footer
          disable-pagination
        >
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- Transport dialogue -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="mode of trasnsport"
                  v-model="mode_of_trasport"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Vehicle Number"
                  v-model="vehicle_no"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Contact No"
                  v-model="contact_no"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            dark
            class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
            @click="updateDispath()"
            >ok</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      offers: [],
      mode_of_trasport: "",
      disMasterID: 0,
      contact_no: "",
      vehicle_no: "",
      OfferHeader: [
        {
          text: "Item",
          align: "",
          sortable: false,
          width: 150,
          value: "item_name",
        },
        {
          text: "LOT NO",
          // width: "150",
          value: "lot_no",
        },
        {
          text: "Tea Type",
          // width: "150",
          value: "tea_type",
        },

        {
          text: "Mark",
          // width: "150",
          value: "mark_code",
        },
        {
          text: "Grade",
          // width: "150",
          value: "grade_code",
        },
        {
          text: "Invoice No",
          // width: "150",
          value: "invoice_number",
        },

        {
          text: "Ordered QTY",
          align: "center",
          sortable: false,
          // width: 150,
          value: "ordered_quantity",
        },
        {
          text: "Ordered Bags",
          align: "center",
          sortable: false,
          // width: 150,
          value: "ordered_no_of_packages",
        },
      ],
    };
  },
  created() {
    this.getOffers();
  },
  methods: {
    async getOffers() {
      axios
        .get("Season-vendor-offer/get-offer-by-vendor-dispatch")
        .then((res) => {
          console.log(res.data);
          this.offers = res.data;
          if (this.offers.length == 0) {
            this.noDataAlert("NO Data Found");
          }
        })
        .catch((err) => {
          this.showErrorAlert(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    confirm(item) {
      this.dialog = true;
      this.disMasterID = item.dismaster_id;
      this.mode_of_trasport = item.transport[0].mode_of_transport;
      this.contact_no = item.transport[0].driver_contact_no;
      this.vehicle_no = item.transport[0].vehicle_no;
    },
    // confirm-dispatch-by-vendor
    async updateDispath() {
      let req = {
        dispatch_master_id: this.disMasterID,
        mode_of_transport: this.mode_of_trasport,
        vehicle_no: this.vehicle_no,
        driver_contact_no: this.contact_no,
      };
      axios
        .patch("dispatch/confirm-dispatch-by-vendor", req)
        .then((res) => {
          if (res.status == 200) this.showSuccessAlert("Dispatch confirmed");
        })
        .catch((err) => {
          this.showErrorAlert(err);
        })
        .finally(() => {
          this.overlay = false;
          this.dialog = false;
        });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,

        timer: 3000,

        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        showConfirmButton: false,
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
      });
    },

    showErrorAlert(message) {
      this.$swal({
        showConfirmButton: false,
        title: "Error",
        text: message,
        type: "error",
        timer: 2500,
        icon: "error",
      });
    },
  },
};
</script>

<style></style>
