import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import VendorOffer from "../modules/vendor-offer.vue";
import UploadOffer from "../modules/upload-offer.vue";
import ViewBids from "../modules/view-bids.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import Dispatch from "../modules/dispatch.vue";

// Dashboard pages

const SignUpBasic = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Pages/Authentication/SignUp/Basic.vue"
  );

Vue.use(VueRouter);

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication Basic",
  children: [
    {
      path: "/authentication/sign-in",
      name: "SignUpBasic",
      component: SignUpBasic,
    },
  ],
};

const routes = [
  {
    path: "/",
    component: AuthBasicLayout,
    name: "Authentication Basic",
    redirect: "/authentication/sign-in",
    children: [
      {
        path: "/authentication/sign-in",
        name: "SignUpBasic",
        component: SignUpBasic,
      },
    ],
  },
  {
    path: "/home",

    component: DashboardLayout,
    children: [
      {
        path: "/",
        name: "VendorOffer",
        component: VendorOffer,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/upload-offer",
        name: "UploadOffer",
        component: UploadOffer,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/view-my-bids",
        name: "ViewBids",
        component: ViewBids,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/dispatch",
        name: "Dispatch",
        component: Dispatch,
        meta: {
          groupName: "Dashboards",
        },
      },
      // ViewOffer,
    ],
  },

  // authBasicPages,
];

const router = new VueRouter({
  routes,
});

export default router;
