<template>
  <div>
    <v-navigation-drawer width="270px" fixed app floating :expand-on-hover="mini" :value="drawer" :right="$vuetify.rtl"
      class="my-4 ms-4 border-radius-lg" :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'" :data-color="sidebarColor"
      :data-theme="sidebarTheme">
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-list-item-title class="title d-flex align-center mb-0">
            <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
              <v-img class="navbar-brand-img ms-3" width="35" style="border-radius: 50%;"
                src="../assets/img/curved-images/EASTEA_LOGO1.jpeg"></v-img>
              <span class="ms-2 font-weight-bold text-sm">Purchase and blend</span>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <hr class="horizontal dark mb-4" />

      <v-list nav dense>
        <v-list-item :ripple="false" link class="mb-0 no-default-hover" :class="child.active ? 'item-active' : ''"
          v-for="child in items" :key="child.title" :to="child.link">
          <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-6 ps-7" v-if="!child.items">
            <v-list-item-title v-text="child.title" @click="listClose($event)"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-content class="ms-6 ps-7 py-0" v-if="child.items">
            <v-list-group prepend-icon="" :ripple="false" sub-group no-action v-model="child.active">
              <template v-slot:activator>
                <span class="v-list-item-mini">{{ child.prefix }}</span>
                <v-list nav dense class="pa-0">
                  <v-list-group :ripple="false" append-icon="fas fa-angle-down me-auto ms-1" active-class="item-active"
                    class="mb-0">
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item v-for="child2 in child.items" :ripple="false" :key="child2.title" :to="child2.link"
                @click="listClose($event)">
                <v-list-item-content>
                  <span class="v-list-item-mini" v-text="child2.prefix"></span>
                  <v-list-item-title v-text="child2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>

        <hr class="horizontal dark my-4" />
      </v-list>

      <v-card class="pa-0 border-radius-lg mt-7 mb-9 mx-5">
        <div class="px-5 py-4 align-center">
          <v-avatar class="" width="180" height="180" min-width="180" rounded>
            <v-img src="../assets/img/curved-images/EASTEA_LOGO.jpeg"></v-img>
          </v-avatar>
        </div>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>
<script>
import ContentFooter from "@/components/Footer.vue";
export default {
  components: {
    ContentFooter,
  },
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],

    items: [
      {
        title: "Home",
        prefix: "H",
        link: "/home",
      },
      { title: "Upload Offer", prefix: "U", link: "/upload-offer" },
      { title: "View Bids", prefix: "V", link: "/view-my-bids" },
      { title: "Dispatch", prefix: "D", link: "/dispatch" },
    ],
  }),
  created() { },

  methods: {
    //   mapActions('cart', [
    //   'addProductToCart'
    // ]),
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
};
</script>
