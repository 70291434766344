import axios from "axios";
import { apiLink } from "../config";

if (localStorage.getItem("token")) {
  axios.defaults.baseURL = apiLink;
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
} else {
  delete axios.defaults.headers.common["Authorization"];
  axios.defaults.baseURL = apiLink;
}
