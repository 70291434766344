<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-card>
      <v-form ref="frm">
        <v-row>
          <v-col cols="4" offset="8">
            Offer No :<label class="text-md text-typo font-weight-bolder ms-1">
              {{ offer_no }}</label
            >
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col cols="12" md="4">
            <v-form ref="frmselect">
              <label class="text-md text-typo font-weight-bolder ms-1">
                Season</label
              >

              <v-select
                v-model="season"
                return-object
                :items="seasons"
                item-text="name"
                item-value="name"
                color="rgba(0,0,0,.6)"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                  mt-5
                "
                :rules="[(v) => !!v || 'Select a Season']"
                outlined
                single-line
                height="48"
                @change="getOfferNo"
                placeholder="Select a Season"
              >
              </v-select>
            </v-form>
          </v-col>
          <v-col cols="12" md="4">
            <label class="text-md text-typo font-weight-bolder ms-1">
              Upload File</label
            >
            <v-file-input
              prepend-inner-icon="mdi-map-marker"
              v-model="selectedFile"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                mb-0
                mt-5
              "
              dense
              height="48"
              :rules="[(v) => !!v || 'Choose a File']"
              outlined
              placeholder="Choose a File"
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="4" align-self="center">
            <v-col>
              <v-btn
                @click="upload"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mt-8
                "
                >upload</v-btn
              ></v-col
            >
            <v-btn
              text
              elevation="0"
              height="48"
              color="green"
              @click="sampleDownload"
              >Download Sample</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      overlay: false,
      seasons: [],
      season: null,
      offer_no: "",
      selectedFile: null,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    initialize() {
      this.overlay = true;
      axios
        .get("season")
        .then((res) => {
          this.seasons = res.data;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    getOfferNo() {
      this.overlay = true;
      axios
        .get("Season-vendor-offer/get-offer-no/" + this.season.id)
        .then((res) => {
          this.offer_no = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    clear() {
      this.season = null;
      this.offer_no = "";
      this.selectedFile = null;
      this.$refs.frm.resetValidation();
      // this.$refs.frmselect.resetValidation();
    },
    upload() {
      if (this.validate()) {
        this.overlay = true;
        const fd = new FormData();
        fd.append("file", this.selectedFile, this.selectedFile.name);
        fd.append("season_id", this.season.id);

        axios
          .post("Season-vendor-offer/upload-private-offer", fd)
          .then((res) => {
            this.showSuccessAlert("Offer Uploaded Succesfully...");
            this.clear();
          })
          .catch((err) => {
            this.showErrorAlert(err.response.data.message);
          })
          .finally(() => {
            this.overlay = false;
          });
      }
    },
    sampleDownload() {
      if (this.$refs.frmselect.validate()) {
        axios
          .get(
            "Season-vendor-offer/download-sample-private-offer/" +
              this.season.id,

            {
              responseType: "blob",
            }
          )
          .then((res) => {
            const url = URL.createObjectURL(
              new Blob([res.data], {
                type: "application/vnd.ms-excel",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "sample-Season-vendor-offer.xlsx");
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    showWarningAlert(message) {
      this.clear();
      // this.resetValidation();
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        showConfirmButton: false,
        timer: 1000,
        icon: "success",
      });
    },

    showErrorAlert(message) {
      this.clear();
      // this.resetValidation();
      this.$swal({
        text: message,
        showConfirmButton: true,
        type: "error",

        icon: "error",
      });
    },
  },
};
</script>

<style></style>
