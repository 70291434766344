<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>

    <v-card elevation="2"
      ><v-row class="ma-2">
        <v-col cols="12" sm="6" md="3">
          <label class="text-md text-typo font-weight-bolder ms-1">
            Season</label
          >
          <v-select
            v-model="season"
            return-object
            :items="seasons"
            item-text="name"
            item-value="name"
            color="rgba(0,0,0,.6)"
            class="
              input-style
              font-size-input
              text-light-input
              placeholder-light
              border-radius-md
              select-style
              mb-0
              mt-5
            "
            outlined
            single-line
            height="43"
            @change="getOfferNo"
          >
          </v-select
        ></v-col>
        <v-col cols="6" sm="6" md="4" align-self="center">
          Offer No :
          <label class="text-md text-typo font-weight-bolder ms-1">
            {{ offer_no }}</label
          >
        </v-col></v-row
      ></v-card
    >

    <v-card elevation="2" class="mt-5" v-if="offer_no != ''">
      <v-form ref="frm">
        <!-- 1 -->
        <v-row class="mx-2">
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Lot No</label
            >
            <v-text-field
              hide-details="auto"
              v-model="addItem.lot_no"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              dense
              flat
              filled
              solo
              height="43"
              placeholder="Lot No"
              persistent-hint
              :rules="[(v) => !!v || 'Lot no is Required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Tea Type</label
            >

            <v-autocomplete
              v-model="addItem.tea_type"
              return-object
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              :items="teaTypes"
              item-text="tea_type_name"
              item-value="tea_type_name"
              outlined
              dense
              chips
              small-chips
              :rules="[(v) => !!v || 'Tea Type is Required']"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Mark
            </label>

            <v-autocomplete
              v-model="addItem.mark_code"
              return-object
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              :items="marks"
              item-text="mark_name"
              item-value="mark_code"
              outlined
              dense
              chips
              small-chips
              :rules="[(v) => !!v || 'Mark is Required']"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Grade</label
            >

            <v-autocomplete
              v-model="addItem.grade_code"
              return-object
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              :items="grades"
              item-text="grade_name"
              item-value="grade_code"
              outlined
              dense
              chips
              small-chips
              :rules="[(v) => !!v || 'Grade is Required']"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <!--  -->

        <!-- 2 -->

        <v-row class="mx-2">
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Invoice Number</label
            >
            <v-text-field
              hide-details="auto"
              v-model.number="addItem.invoice_number"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              dense
              flat
              filled
              solo
              height="43"
              :rules="[(v) => !!v || 'Invoice Number is Required']"
              placeholder="Invoice Number"
              persistent-hint
              min="0"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Gp Date</label
            >

            <v-menu
              ref="menu_gp_date"
              v-model="menu_gp_date"
              :close-on-content-click="false"
              :return-value.sync="menu_gp_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="addItem.gp_date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Gp Date"
                  persistent-hint
                ></v-text-field>
              </template>
              <v-date-picker
                color="green lighten-1"
                header-color="primary"
                v-model="addItem.gp_date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu_gp_date = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu_gp_date.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Gp No</label
            >
            <v-text-field
              v-model="addItem.gp_no"
              hide-details="auto"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              dense
              flat
              filled
              solo
              height="43"
              placeholder="Gp No"
              :rules="[(v) => !!v || 'Gp No is Required']"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Location</label
            >
            <v-text-field
              hide-details="auto"
              v-model="addItem.buying_location"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              dense
              flat
              filled
              solo
              height="43"
              placeholder="Dispatch Location"
              :rules="[(v) => !!v || 'Dispatch Location is Required']"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 3 -->
        <v-row class="mx-2">
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >No: of packages</label
            >
            <v-text-field
              v-model.number="addItem.no_of_packages"
              @change="invoiceWeightCalc"
              hide-details="auto"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              min="0"
              dense
              flat
              filled
              solo
              height="43"
              placeholder="No: of packages"
              :rules="[
                (v) => !!v || 'No: of packages is Required',
                (v) => v >= 0 || 'No: of packages should greater than 0',
              ]"
              persistent-hint
              type="number"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Net weight</label
            >
            <v-text-field
              hide-details="auto"
              v-model.number="addItem.net_weight"
              @change="invoiceWeightCalc"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              min="0"
              dense
              flat
              filled
              solo
              height="43"
              placeholder="Net weight"
              type="number"
              :rules="[
                (v) => !!v || 'Net weight is Required',
                (v) => v >= 0 || ' Net Weight should greater than 0',
              ]"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Gross Net Weight</label
            >
            <v-text-field
              v-model.number="addItem.gross_net_weight"
              min="0"
              hide-details="auto"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              dense
              flat
              filled
              solo
              height="43"
              placeholder="Gross Net Weight"
              type="number"
              :rules="[
                (v) => !!v || 'Gross Net Weight  is Required',
                (v) => v >= 0 || 'Gross Net Weight should greater than 0',
              ]"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Invoice Weight</label
            >
            <!-- v-on="
                (addItem.invoice_weight =
                  addItem.net_weight * addItem.no_of_packages)
              " -->
            <v-text-field
              hide-details="auto"
              v-model.number="addItem.invoice_weight"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              dense
              flat
              filled
              solo
              height="43"
              type="number"
              min="0"
              :rules="[
                (v) => !!v || 'Invoice Weight is Required',
                (v) => v >= 0 || ' Net Weight should greater than 0',
              ]"
              placeholder="Invoice Weight"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- 4 -->

        <v-row class="mx-2">
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Offer Price</label
            >
            <v-text-field
              hide-details="auto"
              v-model.number="addItem.offer_price"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              dense
              flat
              filled
              solo
              height="43"
              placeholder="Offer Price"
              type="number"
              min="0"
              :rules="[
                (v) => !!v || 'Offer Price is Required',
                (v) => v > 0 || ' Net Weight should greater than 0',
              ]"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Period of Manufacturing</label
            >
            <v-text-field
              hide-details="auto"
              v-model="addItem.period_of_manufacture"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
                mt-5
              "
              dense
              flat
              filled
              solo
              height="43"
              placeholder="Period of Manufacturing"
              type="text"
              min="0"
              :rules="[(v) => !!v || 'Period of Manufacturing is Required']"
              persistent-hint
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label class="text-md text-typo font-weight-bolder ms-1"
              >Expiry date</label
            >

            <v-menu
              ref="menu_expiry_date"
              v-model="menu_expiry_date"
              :close-on-content-click="false"
              :return-value.sync="menu_expiry_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="addItem.expiry_date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Expiry date"
                  persistent-hint
                ></v-text-field>
              </template>
              <v-date-picker
                color="green lighten-1"
                header-color="primary"
                v-model="addItem.expiry_date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu_expiry_date = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu_expiry_date.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8" offset="4" offset-md="7" md="4">
            <v-btn
              @click="cancelFrm"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-capitalize
                btn-ls btn-secondary
                bg-light
                py-3
                px-6
                mx-2
              "
              >Cancel</v-btn
            >

            <v-btn
              @click="addToArray"
              elevation="0"
              :ripple="false"
              height="43"
              dark
              class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
              >Add</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <div v-if="dataArray.length > 0">
      <v-card elevation="2" class="mt-5">
        <v-card-text class="px-0 py-0 mt-5">
          <v-data-table
            :headers="headers"
            :items="dataArray"
            :search="search"
            class="table"
            hide-default-footer
            @page-count="pageCount = $event"
            mobile-breakpoint="0"
            disable-pagination
            ><template v-slot:[`item.actions`]="{ item }">
              <!-- <span> Example test {{ item.district }}</span> -->
              <v-btn
                @click="editItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class=""
                color="green"
                >Edit
              </v-btn>

              <v-btn
                @click="deleteItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="ml-5"
                color="#FF0000"
              >
                delete
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-row>
          <v-col offset-md="10">
            <v-btn
              @click="save"
              elevation="0"
              :ripple="false"
              height="43"
              dark
              class="
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
                mt-7
              "
              >Save</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </div>

    <!-- 

     -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDelete"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
            >Cancel</v-btn
          >

          <v-btn
            @click="deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Ok</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      buttonText: "Add",
      overlay: false,

      menu_gp_date: false,
      menu_expiry_date: false,

      editedIndex: -1,
      dialogDelete: false,
      search: "",
      seasons: [],
      season: null,
      offer_no: "",
      dataArray: [],
      teaTypes: [],
      marks: [],
      grades: [],
      addItem: {
        lot_no: "",
        tea_type: "",
        mark_code: "",
        grade_code: "",
        invoice_number: "",
        no_of_packages: 0,
        gross_net_weight: 0,
        net_weight: 0,
        invoice_weight: 0,
        period_of_manufacture: "",
        offer_price: "",
        gp_no: "",
        buying_location: "",
        buying_location: "",
        gp_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        expiry_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        buying_location: "",
      },
      defaultItem: {
        lot_no: "",
        tea_type: "",
        mark_code: "",
        grade_code: "",
        invoice_number: "",
        no_of_packages: 0,
        gross_net_weight: 0,
        net_weight: 0,
        invoice_weight: 0,
        period_of_manufacture: "",
        offer_price: "",
        gp_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        expiry_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        buying_location: "",
      },
      headers: [
        {
          text: "Lot Number",
          align: "center",
          sortable: false,
          width: 175,
          value: "lot_no",
        },
        {
          text: "Tea Type",
          align: "center",
          sortable: false,
          width: 175,
          value: "tea_type.tea_type_name",
        },
        {
          text: "Mark",
          align: "center",
          sortable: false,
          width: 175,
          value: "mark_code.mark_code",
        },
        {
          text: "Grade Code",
          align: "center",
          sortable: false,
          width: 175,
          value: "grade_code.grade_code",
        },
        {
          text: "Invoice Number",
          align: "center",
          sortable: false,
          width: 175,
          value: "invoice_number",
        },
        {
          text: "No of Packages",
          align: "center",
          sortable: false,
          width: 175,
          value: "no_of_packages",
        },
        {
          text: "Gross Net Weight",
          align: "center",
          sortable: false,
          width: 200,
          value: "gross_net_weight",
        },
        {
          text: "Net weight",
          align: "center",
          sortable: false,
          width: 175,
          value: "net_weight",
        },
        {
          text: "Invoice Weight",
          align: "center",
          sortable: false,
          width: 175,
          value: "invoice_weight",
        },
        {
          text: "Period of Manufacturing",
          align: "center",
          sortable: false,
          width: 220,
          value: "period_of_manufacture",
        },
        {
          text: "Manufacturing End Date",
          align: "center",
          sortable: false,
          width: 220,
          value: "mfge_date",
        },
        {
          text: "Expiry Date",
          align: "center",
          sortable: false,
          width: 220,
          value: "expiry_date",
        },

        {
          text: "Offer Price",
          align: "center",
          sortable: false,
          width: 175,
          value: "offer_price",
        },
        {
          text: "Gp No",
          align: "center",
          sortable: false,
          width: 175,
          value: "gp_no",
        },
        {
          text: "Gp Date",
          align: "center",
          sortable: false,
          width: 175,
          value: "gp_date",
        },
        {
          text: "Dispatch Location",
          align: "center",
          sortable: false,
          width: 175,
          value: "buying_location",
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
          width: 175,
          value: "actions",
        },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    reset() {
      this.$refs.frm.resetValidation();
    },
    clear() {
      this.dataArray = [];
    },
    async initialize() {
      this.overlay = true;
      await axios.get("season").then((res) => {
        this.seasons = res.data;
      });

      await axios.get("tea-type").then((res) => {
        this.teaTypes = res.data;
      });

      await axios.get("marks").then((res) => {
        this.marks = res.data;
      });

      await axios.get("grade").then((res) => {
        this.grades = res.data;
      });

      this.overlay = false;
    },
    getOfferNo() {
      this.overlay = true;
      this.clear();
      axios
        .get("Season-vendor-offer/get-offer-no/" + this.season.id)
        .then((res) => {
          this.offer_no = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    invoiceWeightCalc() {
      this.addItem.invoice_weight =
        this.addItem.net_weight * this.addItem.no_of_packages;
    },
    addToArray() {
      if (this.validate()) {
        if (this.editedIndex == -1) {
          this.dataArray.push(this.addItem);
        } else {
          this.dataArray.splice(this.editedIndex, 1, this.addItem);
        }
        this.addItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.reset();
        this.buttonText = "Add";
      }
    },
    editItem(item) {
      this.editedIndex = this.dataArray.indexOf(item);
      this.addItem = Object.assign({}, item);
      this.buttonText = "Update";
    },
    async deleteItem(item) {
      this.editedIndex = this.dataArray.indexOf(item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      try {
        this.dataArray.splice(this.editedIndex, 1);

        this.showSuccessAlert(`Data Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.reset();
      this.$nextTick(() => {
        this.addItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        showConfirmButton: false,
        timer: 1000,
        icon: "success",
      });
    },

    showErrorAlert(message) {
      this.reset();
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 2500,
        icon: "error",
      });
    },

    save() {
      this.overlay = true;
      let data = {
        season: this.season,
        offer_no: this.offer_no,
        PrivateVendorOffer: this.dataArray,
      };
      console.log("Data", data);
      axios
        .post("Season-vendor-offer/season-vendor-offer", data)
        .then((res) => {
          this.showSuccessAlert("Data Saved");
          this.reset();
          this.addItem = Object.assign({}, this.defaultItem);
          this.dataArray = [];
          this.season = null;
          this.offer_no = "";
        })
        .catch((err) => {
          this.showErrorAlert(err.response.data.message);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    cancelFrm() {
      this.addItem = this.defaultItem;
      this.buttonText = "Add";
      this.reset();
    },
  },
};
</script>

<style></style>
