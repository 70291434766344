<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-card elevation="2">
      <v-form ref="frm">
        <v-row class="ma-2">
          <v-col>
            <label class="text-md text-typo font-weight-bolder ms-1">
              Season</label
            >
            <v-select
              v-model="season"
              return-object
              :items="seasons"
              item-text="name"
              item-value="name"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mb-0
                mt-5
              "
              :rules="[(v) => !!v || 'Select a Season']"
              outlined
              single-line
              height="48"
              @change="getOfferNo"
            >
            </v-select>
          </v-col>
          <v-col>
            <label class="text-md text-typo font-weight-bolder ms-1">
              Offer No</label
            >
            <v-select
              v-model="offerno"
              return-object
              :items="offernos"
              item-text="offer_no"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                border-radius-md
                select-style
                mb-0
                mt-5
              "
              :rules="[(v) => !!v || 'Select a Offer No']"
              outlined
              single-line
              height="48"
            >
            </v-select>
          </v-col>
          <v-col align-self="center"
            ><v-btn
              @click="search"
              elevation="0"
              :ripple="false"
              height="43"
              dark
              class="
                text-capitalize
                btn-ls btn-primary
                bg-success
                py-3
                px-6
                mt-3
              "
              >Search</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-card
      class="card-shadow border-radius-xl mt-6"
      v-if="dataArray.length > 0"
    >
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="dataArray"
          hide-default-footer
          class="table"
          disable-pagination
          item-key="id"
          v-model="selected_Bids"
          show-select
          ><template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn
                @click="confirmSelected()"
                elevation="0"
                :ripple="false"
                color="green"
                dark
                class="text-capitalize btn-ls btn-primary mr-10"
                >Confirm Selected </v-btn
              ><v-btn
                @click="rejectSelected()"
                elevation="0"
                :ripple="false"
                dark
                class="text-capitalize btn-ls btn-primary mr-10"
                color="red"
                >Reject Selected
              </v-btn></v-toolbar
            >
          </template>
          <template v-slot:[`header.data-table-select`]="{ item }">
            <v-checkbox :value="item" v-show="false"></v-checkbox>
          </template>
          <template
            v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
          >
            <div v-if="item.bid.length > 0">
              <div>
                <v-simple-checkbox
                  v-if="
                    item.bid[item.bid.length - 1].bid_status == 'Pending'
                      ? true
                      : false
                  "
                  :value="isSelected"
                  @input="select($event)"
                ></v-simple-checkbox>
              </div>
            </div>
          </template>

          <template v-slot:[`item.mfge_date`]="{ item }">
            {{ dateFormat(item.mfge_date) }}</template
          >
          <template v-slot:[`item.mfgs_date`]="{ item }"
            >{{ dateFormat(item.mfgs_date) }}
          </template>
          <template v-slot:[`item.gp_date`]="{ item }">
            {{ dateFormat(item.gp_date) }}
          </template>
          <template v-slot:[`item.bid1`]="{ item }">
            <label v-if="item.bid.length > 0">
              {{ item.bid[0].bid_value }}</label
            >
          </template>
          <template v-slot:[`item.bid2`]="{ item }">
            <label v-if="item.bid.length > 1">
              {{ item.bid[1].bid_value }}</label
            >
          </template>
          <template v-slot:[`item.bid3`]="{ item }">
            <label v-if="item.bid.length > 2">
              {{ item.bid[2].bid_value }}</label
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="item.bid.length > 0">
              <v-chip
                label
                small
                :ripple="false"
                v-if="
                  item.bid[item.bid.length - 1].bid_status != 'Pending'
                    ? true
                    : false
                "
                color="green lighten-3"
                >{{ item.bid[item.bid.length - 1].bid_status }}ed
              </v-chip>

              <div v-else>
                <v-btn
                  @click="confirm(item)"
                  icon
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="mr-5"
                  color="green"
                  >Confirm </v-btn
                ><v-btn
                  @click="reject(item)"
                  icon
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="ml-5"
                  color="red"
                  >Reject
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="750px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Bid List</v-card-title
        >
        <label
          class="pt-0 text-h5 text-typo justify-center"
          v-if="bids.length == 0"
          >No Bid Found</label
        >
        <div v-else>
          <v-row v-for="(bid, i) in bids" :key="bid.id">
            <v-col>
              <label class="text-md text-typo font-weight-bolder ms-1"
                >Bid value {{ i + 1 }}</label
              >
              <v-text-field
                :value="bid.bid_value"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                "
                dense
                flat
                filled
                solo
                height="48"
                readonly
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col
              align-self="center"
              v-if="bid.bid_status != 'Pending' ? true : false"
            >
              <label class="text-md text-typo font-weight-bolder ms-1"
                >{{ bid.bid_status }}ed</label
              >
            </v-col>
            <v-col v-else>
              <label class="text-md text-typo font-weight-bolder ms-1"
                >Status</label
              >
              <v-select
                color="rgba(0,0,0,.6)"
                v-model="status.data[i]"
                :items="bidStatus"
                item-text="status"
                item-value="status"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  border-radius-md
                  select-style
                  mb-0
                "
                outlined
                single-line
                height="48"
                placeholder="Status"
              >
              </v-select>

              <v-btn
                @click="save"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
                >Save</v-btn
              >
            </v-col>
          </v-row>

          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>

            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import formater from "../formatter/dateFormatter";
export default {
  data() {
    return {
      selected_Bids: [],
      dialog: false,
      overlay: false,
      season: null,
      seasons: [],
      offernos: [],
      offerno: null,
      dataArray: [],
      bids: [],
      bidStatus: [{ status: "Confirm" }, { status: "Reject" }],
      status: { data: [] },

      viewItem: -1,
      headers: [
        {
          text: "Lot No:",

          sortable: true,
          width: 200,
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Mark",

          width: 150,
          sortable: false,
          value: "mark_id.mark_name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Grade",
          sortable: false,
          width: 100,
          value: "grade_id.grade_name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Invoice No:",

          sortable: false,
          width: 50,
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        // {
        //   text: "Tea Type",
        //   align: "center",
        //   width: 50,
        //   sortable: false,
        //   value: "tea_type",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },

        {
          text: "Bags",

          sortable: false,
          width: 50,
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-7",
        },

        // {
        //   text: "Gross Net Wt",
        //   align: "center",
        //   sortable: false,
        //   width: 50,
        //   value: "gross_net_weight",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "Net WT",

          sortable: false,
          width: 50,
          value: "net_weight",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Invoice WT",

          sortable: false,
          width: 50,
          value: "invoice_weight",
          class: "text-secondary font-weight-bolder opacity-7",
        },

        {
          text: "Offer Price",

          sortable: false,
          width: 50,
          value: "offer_price",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        // {
        //   text: "Gp No:",
        //   align: "center",
        //   sortable: false,
        //   width: 50,
        //   value: "gp_no",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "Bid 1",

          sortable: false,
          width: 50,
          value: "bid1",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Bid 2",

          sortable: false,
          width: 50,
          value: "bid2",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Bid 3",

          sortable: false,
          width: 50,
          value: "bid3",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Action",

          sortable: false,
          // width: 200,
          class: "text-secondary font-weight-bolder opacity-7",
          value: "actions",
        },
        // {
        //   text: "Period Of Manufacture",
        //   align: "center",
        //   sortable: false,
        //   width: 150,
        //   value: "period_of_manufacture",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        // {
        //   text: "Gp Date",
        //   align: "center",
        //   sortable: false,
        //   width: 120,
        //   value: "gp_date",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        // {
        //   text: "Dispatch Location",
        //   align: "center",
        //   sortable: false,
        //   width: 100,
        //   class: "text-secondary font-weight-bolder opacity-7",
        //   value: "buying_location",
        // },
      ],
    };
  },
  created() {
    this.getSeason();
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    getSeason() {
      this.overlay = true;
      axios
        .get("private-vendor-offer/vendor-offer-season")
        .then((res) => {
          this.seasons = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    getOfferNo() {
      this.overlay = true;
      this.offernos = [];
      this.offerno = null;
      axios
        .get(`private-vendor-offer/vendor-offer-season/${this.season.id}`)
        .then((res) => {
          this.offernos = res.data;
          console.log("this.offernos", this.offernos);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    search() {
      if (this.validate()) {
        this.dataArray = [];

        axios
          .get(
            `private-vendor-bid/get-bids-by/offer-no/season/data?season_id=${this.season.id}&offer_no=${this.offerno.offer_no}`
          )
          .then((res) => {
            this.dataArray = res.data.pvtVendorOffr;

            if (this.dataArray.length == 0) {
              this.noDataFoundAlert("No Data Found...");
            } else {
              //Reversing bid arry order(Data from typeorm order by updated date)

              this.dataArray.forEach((el) => {
                el.bid.sort((a, b) => {
                  return a.id - b.id;
                });
              });

              this.dataArray.sort((a, b) => {
                const lotA = a.lot_no.toUpperCase();
                const lotB = b.lot_no.toUpperCase();
                if (lotA < lotB) {
                  return -1;
                }
                if (lotA > lotB) {
                  return 1;
                }

                return 0;
              });
            }

            // console.log("this.dataArray.", this.dataArray);
          })
          .catch((err) => {
            console.log(err);
          });
        // console.log("this.dataArray", this.dataArray);
      }
    },
    confirm(item) {
      // console.log(item);

      let req = {
        id: item.bid[item.bid.length - 1].id,
        bid_status: "Confirm",
        private_vendor_offer_id:
          item.bid[item.bid.length - 1].private_vendor_offer_id,
      };
      axios
        .post("private-vendor-bid/update-bid-status", {
          update_data: req,
        })
        .then(() => {
          this.showSuccessAlert("Data Saved");
          item.bid[item.bid.length - 1].bid_status = "Confirm";
        })
        .catch((er) => {
          this.showErrorAlert(er.response.data.message);
        });
    },

    reject(item) {
      console.log(item);
      let req = {
        id: item.bid[item.bid.length - 1].id,
        bid_status: "Reject",
        private_vendor_offer_id:
          item.bid[item.bid.length - 1].private_vendor_offer_id,
      };
      axios
        .post("private-vendor-bid/update-bid-status", {
          update_data: req,
        })
        .then(() => {
          this.showSuccessAlert("Data Saved");
          item.bid[item.bid.length - 1].bid_status = "Reject";
        })
        .catch((er) => {
          this.showErrorAlert(er.response.data.message);
        });
    },
    viewBids(item) {
      this.viewItem = this.dataArray.indexOf(item);
      console.log("item", item);
      this.bids = [];
      this.bids = item.bid;
      console.log("this.bids", this.bids);
      this.dialog = true;
    },
    save() {
      console.log(this.status);
      this.status.data.forEach((el, i) => {
        this.bids[i].bid_status = el;
      });
      this.dataArray[this.viewItem].bid = this.bids;
      this.viewItem = -1;
      console.log("req", this.bids);

      axios
        .post("private-vendor-bid/update-bid-status", {
          update_data: this.bids,
        })
        .then(() => {
          this.showSuccessAlert("Data Saved");
        })
        .catch((er) => {
          this.showErrorAlert(er.response.data.message);
        })
        .finally(() => {
          this.status.data = [];
          this.dialog = false;
        });
    },
    async confirmSelected() {
      if (this.selected_Bids.length > 0) {
        for await (let item of this.selected_Bids) {
          let req = {
            id: item.bid[item.bid.length - 1].id,
            bid_status: "Confirm",
            private_vendor_offer_id:
              item.bid[item.bid.length - 1].private_vendor_offer_id,
          };
          axios
            .post("private-vendor-bid/update-bid-status", {
              update_data: req,
            })
            .then(() => {
              item.bid[item.bid.length - 1].bid_status = "Confirm";
            })
            .catch((er) => {
              this.showErrorAlert(er.response.data.message);
            });
        }
        this.showSuccessAlert("Data Saved");
        this.selected_Bids = [];
      } else {
        this.showErrorAlert("Select Atleast one row");
      }
    },
    async rejectSelected() {
      if (this.selected_Bids.length > 0) {
        for await (let item of this.selected_Bids) {
          let req = {
            id: item.bid[item.bid.length - 1].id,
            bid_status: "Reject",
            private_vendor_offer_id:
              item.bid[item.bid.length - 1].private_vendor_offer_id,
          };
          axios
            .post("private-vendor-bid/update-bid-status", {
              update_data: req,
            })
            .then(() => {
              item.bid[item.bid.length - 1].bid_status = "Reject";
            })
            .catch((er) => {
              this.showErrorAlert(er.response.data.message);
            });
        }
        this.showSuccessAlert("Data Saved");
        this.selected_Bids = [];
      } else {
        this.showErrorAlert("Select Atleast one row");
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        showConfirmButton: false,
        timer: 1000,
        icon: "success",
      });
    },
    noDataFoundAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
      // s
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 2500,
        icon: "error",
      });
    },
    dateFormat(date) {
      return formater.formatDate(date);
    },
  },
};
</script>

<style>
.centered-input input {
  text-align: center;
}
</style>
